import { Box, Divider, Grid, Typography } from "@mui/material";
import i18n from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Logo from "../../components/Logo";

import { content } from "../../utils/content";
import { NAV_OPTIONS } from "../../utils/optionUtils";

import { styles } from "./style";

const Footer = () => {
  const { t } = useTranslation();

  const handleOnClickOption = (id) => (event) => {
    // if (typeof window !== "undefined") {
    document.querySelector(`#${id}`)?.scrollIntoView({
      behavior: "smooth",
    });
    // }
  };

  return (
    <Box sx={styles.footerRoot}>
      <Box component="div" sx={styles.sectionContainer}>
        <Box component="div" sx={styles.sectionBody}>
          <Grid container direction="row" spacing={0} sx={styles.footerRow}>
            <Grid item xs={12} md={4}>
              <Logo />

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textLabel}>{t("address")}</Typography>

                <Box sx={styles.bulletSeperator} />

                <Typography sx={styles.textValue}>
                  {content.footer.address}
                </Typography>
              </Box>

              <Box sx={styles.textContainer}>
                <Typography sx={styles.textLabel}>{t("email")}</Typography>

                <Box sx={styles.bulletSeperator} />

                <Link
                  href={`mailto:${content.footer.email}`}
                  style={{ textDecoration: "none" }}
                >
                  <Typography sx={styles.textLink}>
                    {content.footer.email}
                  </Typography>
                </Link>
              </Box>
            </Grid>

            <Grid item xs={6} md={2}>
              {Array.from(NAV_OPTIONS)
                .slice(0, 4)
                .map(([key, value]) => {
                  return (
                    <Link
                      key={value.LABEL}
                      to={`${i18n.language}/${value.PATH}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography sx={styles.textLink}>
                        {t(value.LABEL)}
                      </Typography>
                    </Link>
                  );
                })}
            </Grid>

            <Grid item xs={6} md={5}>
              {Array.from(NAV_OPTIONS)
                .slice(4)
                .map(([key, value]) => {
                  return (
                    <Link
                      key={value.LABEL}
                      to={`${i18n.language}/${value.PATH}`}
                      style={{ textDecoration: "none" }}
                    >
                      <Typography sx={styles.textLink}>
                        {t(value.LABEL)}
                      </Typography>
                    </Link>
                  );
                })}
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={0} sx={styles.footerRow}>
            <Grid item xs={12} md={12}>
              <Divider sx={styles.footerDivider} />
            </Grid>
          </Grid>

          <Grid container direction="row" spacing={0} sx={styles.footerRow}>
            {/* <Grid item xs={12} md={2}>
            <Logo  />
          </Grid> */}

            <Grid item xs={12} md={5}>
              <Box sx={styles.socialMediaContainer}>
                {/* {content.footer?.socialMedia.map((media, index) => {
                return (
                  <Link
                    href={media.link}
                    key={index}
                    underline="none"
                    target="_blank"
                    rel="noopener"
                  >
                    <Box sx={styles.socialMediaIcon}>{media?.icon}</Box>
                  </Link>
                );
              })} */}
              </Box>
            </Grid>

            <Grid item xs={12} md={4}>
              <Typography sx={styles.copyrightText}>
                {t(content.footer.copyright)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
