import { Box, Divider, Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import BackgroundSolarAnimated from "../../components/BackgroundSolarAnimated";
import EventsListItem from "../../components/eventListPageComponents/EventsListItem";
import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../../components/SlideTransition";

import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const EventsList = () => {
  const [setRef, visible] = useInView({
    threshold: 0,
  });
  const { t } = useTranslation();

  return (
    <Stack
      direction={"row"}
      spacing={0}
      alignItems={"start"}
      ref={setRef}
      sx={styles.root}
    >
      <Box sx={styles.background}>
        <Box sx={styles.backgroundOverlay} />
      </Box>

      <SectionLayout id={NAV_SUBROUTES.get("EVENTS_LIST").ID} style={{ pt: 0 }}>
        <SlideTransition appear={visible} timeout={700}>
          <Stack spacing={4} justifyContent={"center"} sx={styles.header}>
            <Typography sx={styles.headerText}>
              {t(content.events.header)}
            </Typography>

            <Typography sx={styles.bodyText}>
              {t(content.events.subheader)}
            </Typography>
          </Stack>
        </SlideTransition>

        {/* <BackgroundSolarAnimated /> */}

        <Box sx={styles.body}>
          <Stack alignItems="center" spacing={8} sx={styles.section}>
            <Box
              sx={{
                ...styles.gridContainer,
                justifyContent:
                  content.events.items.find((f) => f.status === "completed")
                    .items.length > 1
                    ? "space-between"
                    : "center",
              }}
            >
              {content.events.items.find((f) => f.status === "completed").items
                .length > 0 ? (
                content.events.items
                  .find((f) => f.status === "completed")
                  .items.map((item, idx) => {
                    return (
                      <EventsListItem data={item} index={idx} visible={true} />
                    );
                  })
              ) : (
                <Box sx={styles.nullContainer}>{content.events.null}</Box>
              )}
            </Box>
          </Stack>
        </Box>
      </SectionLayout>
    </Stack>
  );
};

export default EventsList;
