import React, { lazy, Suspense } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Route, Routes } from "react-router-dom";

import Blog from "./pages/Blog";
import ContactForm from "./components/ContactForm";
import DocsLayout from "./pages/DocsPage";
import Event from "./pages/Event";
import EventsList from "./pages/EventsList";
import HomePage from "./pages/HomePage";
import OverallLayout from "./layouts/OverallLayout";

import { content } from "./utils/content";
import {
  DOC_ROUTES,
  ENTERPRISE_ROUTES,
  NAV_ROUTES,
  NAV_SUBROUTES,
  UNIVERSAL_ROUTES,
} from "./utils/routes";

import "./App.css";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        {/* HTML Meta Tags */}
        <title>{content.meta.title}</title>
        <meta name="description" content={content.meta.description} />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <Routes>
        <Route exact path="/" element={<OverallLayout />}>
          <Route
            exact
            path={NAV_ROUTES.get("HOME").PATH}
            element={<HomePage />}
          />

          <Route
            exact
            path={`${NAV_ROUTES.get("HOME").PATH}/${
              NAV_SUBROUTES.get("DOCS").PATH
            }`}
            element={<DocsLayout />}
          >
            {Array.from(DOC_ROUTES).map(([key, value], index) => {
              return (
                <Route
                  key={index}
                  path={value.PATH}
                  element={value.ELEMENT}
                ></Route>
              );
            })}

            {Array.from(ENTERPRISE_ROUTES).map(([key, value], index) => {
              return (
                <Route
                  key={index}
                  path={value.PATH}
                  element={value.ELEMENT}
                ></Route>
              );
            })}

            {Array.from(UNIVERSAL_ROUTES).map(([key, value], index) => {
              return (
                <Route
                  key={index}
                  path={value.PATH}
                  element={value.ELEMENT}
                ></Route>
              );
            })}
          </Route>

          <Route
            exact
            path={`${NAV_ROUTES.get("HOME").PATH}/${
              NAV_SUBROUTES.get("BLOG").PATH
            }`}
            element={<Blog />}
          />

          <Route
            exact
            path={`${NAV_ROUTES.get("HOME").PATH}/${
              NAV_SUBROUTES.get("EVENTS_LIST").PATH
            }`}
            element={<EventsList />}
          />

          <Route
            path={`${NAV_ROUTES.get("HOME").PATH}/${
              NAV_SUBROUTES.get("EVENT").PATH
            }`}
            element={<Event />}
          />

          <Route
            path={`${NAV_ROUTES.get("HOME").PATH}/${
              NAV_SUBROUTES.get("CONTACT").PATH
            }`}
            element={<ContactForm />}
          />
        </Route>
      </Routes>
    </HelmetProvider>
  );
}

export default App;
