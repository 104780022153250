import { FormControl, MenuItem, Select } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { GlobalContext } from "../../contexts/GlobalContext";
import { TRANSLATE_OPTIONS } from "../../utils/optionUtils";

import { styles } from "./styles";

const AigcLanguageSelect = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { aigcLanguage, loadingTranslation } = state;

  const { t } = useTranslation();

  const [languageSelection, setLanguageSelection] = useState(aigcLanguage);

  useEffect(() => {
    if (aigcLanguage) {
      setLanguageSelection(aigcLanguage);
    }
  }, [aigcLanguage]);

  const handleChangeLanguage = (event) => {
    setLanguageSelection(event.target.value);

    dispatch({
      type: "SET_AIGC_LANGUAGE",
      payload: event.target.value,
    });
  };

  return (
    <FormControl size="small">
      <Select
        disabled={loadingTranslation}
        displayEmpty
        inputProps={{ sx: styles.selectInputProps }}
        onChange={handleChangeLanguage}
        value={languageSelection}
      >
        <MenuItem disabled sx={styles.menuItem} value="">
          {t("Translate")}
        </MenuItem>
        {Array.from(TRANSLATE_OPTIONS).map(([key, value]) => {
          return (
            <MenuItem key={key} sx={styles.menuItem} value={value.VALUE}>
              {value.LABEL}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AigcLanguageSelect;
