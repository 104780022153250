import { glass, theme } from "../../utils/theme";

// import deco from "../../assets/background/wave-bg.png";

export const styles = {
  background: {
    background:
      "linear-gradient(360deg, rgba(4, 104, 214, 0.34) 1.09%, rgba(225, 150, 198, 0.34) 42.33%, rgba(255, 190, 170, 0.34) 69.9%)",
    backgroundPosition: "center bottom !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    filter: "brightness(2)",
    height: "100%",
    right: { xs: 0, sm: 0 },
    top: 0,
    position: "absolute",
    width: { xs: "100%", sm: "100%" },
    zIndex: -1,
  },
  backgroundOverlay: {
    // background: `url(${deco})`,
    backgroundPosition: "right bottom !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    bottom: { xs: "2%", sm: "-5%" },
    filter: "brightness(0) grayscale(1) opacity(0.15)",
    height: "70%",
    left: { xs: 0, sm: 0 },
    position: "absolute",
    width: { xs: "300%", sm: "100%" },
    zIndex: -2,
  },
  bodyText: {
    color: theme.palette.grays[600],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.08,
    },
    fontWeight: 400,
    mb: { xs: 4, sm: 0 },
    mt: { xs: 6, sm: 6 },
    textAlign: { xs: "center", sm: "start" },
    textTransform: "none",
  },
  button: {
    fontSize: {
      xs: theme.typography.fontSize * 0.75,
      sm: theme.typography.fontSize * 0.88,
    },
    mt: { xs: 8, sm: 8 },
    textTransform: "none",
    width: { xs: "100%", sm: 240 },
  },
  contactBackground: {
    height: "50%",
    right: { xs: "-90%", sm: "-100%" },
    position: "absolute",
    top: { xs: "20%", sm: "-60%" },
    zIndex: -1,
  },
  contactRoot: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
    position: "relative",
    width: "100%",
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: theme.typography.fontSize * 0.88,
  },
  formContainer: {
    display: "flex",
    flexDirection: "column",
    mb: { xs: 12, sm: 12 },
    mt: { xs: 12, sm: 12 },
    width: "100%",
  },
  formRow: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    flexWrap: "wrap",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      minWidth: "80%",
      marginBottom: theme.spacing(0),
      marginTop: theme.spacing(0),
    },
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: { xs: "center", sm: "start" },
    position: "relative",
    width: { xs: "100%", sm: "100%" },
  },
  headerText: {
    fontSize: {
      xs: theme.typography.fontSize * 3,
      sm: theme.typography.fontSize * 4,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1, md: 1.1 },
    textAlign: { xs: "center", sm: "center" },
    textTransform: "capitalize",
  },
  input: {
    background: glass.background,
    backdropFilter: glass.backdropFilter,
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    boxShadow: "none",
    flex: 1,
    marginRight: theme.spacing(2),
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(2),
      width: "100%",
    },
  },
  inputBase: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
    padding: 2,
    width: "100%",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  selectButton: {
    alignItems: "center",
    background: glass.background,
    backdropFilter: glass.backdropFilter,
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    color: theme.palette.grays[500],
    cursor: "pointer",
    display: "flex",
    mb: 0.5,
    padding: 3,
  },
  selectFormContainer: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    mr: 2,
    position: "relative",
    width: "100%",
    [theme.breakpoints.only("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  selectFormLabel: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
  },
  selectFormValue: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 1,
  },
  selectMenu: {
    alignItems: "flex-start",
    background: glass.background,
    backdropFilter: glass.backdropFilter,
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 1,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: `${theme.spacing(3)} 0`,
    width: "100%",
    zIndex: 1,
  },
  selectMenuLabel: {
    color: theme.palette.grays[500],
    fontSize: theme.typography.fontSize * 0.88,
    mb: 1.2,
    padding: `0 ${theme.spacing(5)}`,
  },
  selectOption: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    width: "100%",
    "&:hover": {
      background: "rgba(210, 210, 210, 0.2)",
    },
  },
  selectOptionLabel: {
    fontSize: theme.typography.fontSize * 1,
    color: theme.palette.grays.black,
  },
  selectOptionLabelActive: {
    fontSize: theme.typography.fontSize * 1,
    color: theme.palette.primary.main,
  },
};
