import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TbPointFilled } from "react-icons/tb";
import { useParams } from "react-router-dom";

import AigcLanguageSelect from "../../components/AigcLanguageSelect";
import MarkdownContainer from "../../components/docPageComponents/MarkdownContainer";
import SectionLayout from "../../layouts/SectionLayout";

import { fetchTranslations } from "../../api/AigcApi";
import { GlobalContext } from "../../contexts/GlobalContext";
import { content } from "../../utils/content";
import { theme } from "../../utils/theme";

import { styles } from "./style";

const Event = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const { aigcLanguage } = state;

  const { eventPath } = useParams();

  const [defaultEventContent, setDefaultEventContent] = useState(undefined);
  const [eventContent, setEventContent] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);

    const upcoming = content.events.items.find(
      (f) => f.status === "upcoming"
    ).items;

    const completed = content.events.items.find(
      (f) => f.status === "completed"
    ).items;

    const combined = [...upcoming, ...completed];

    const currentEvent = combined.find((item) => item.path === eventPath);

    setDefaultEventContent(currentEvent);
    setEventContent(currentEvent);

    setIsLoading(false);

    return () => {
      // Reset the aigc language selection on event page change
      dispatch({
        type: "SET_AIGC_LANGUAGE",
        payload: "",
      });
    };
  }, []);

  useEffect(() => {
    if (!aigcLanguage) return;

    if (aigcLanguage !== "en") {
      // Call translate service for non-english languages
      // translateContent(defaultEventContent.title, "text", "title");
      translateContent(defaultEventContent.venue, "text", "venue");
      translateContent(defaultEventContent.date, "text", "date");
    } else {
      // Restore default markdown content (English currently)
      setEventContent(defaultEventContent);
    }
  }, [aigcLanguage]);

  const translateContent = async (text, type, key) => {
    dispatch({
      type: "SET_LOADING_TRANSLATION",
      payload: true,
    });

    const formData = {
      dest: aigcLanguage,
      text,
      type,
    };

    const response = await fetchTranslations(formData);

    if (response.status === "resolved") {
      setEventContent((prev) => {
        return { ...prev, [key]: response.data };
      });
    }

    dispatch({
      type: "SET_LOADING_TRANSLATION",
      payload: false,
    });
  };

  return (
    <SectionLayout style={{ minHeight: "80vh" }}>
      {!isLoading && eventContent ? (
        <Box sx={styles.root}>
          <Stack alignItems={"center"} spacing={2}>
            <Typography sx={styles.header}>{eventContent.title}</Typography>

            <Stack
              alignItems="center"
              direction="row"
              justifyContent={"start"}
              spacing={1}
              sx={{ color: theme.palette.grays[700] }}
            >
              <Typography sx={styles.subheader}>
                {eventContent.venue}
              </Typography>

              <TbPointFilled size="0.5em" />

              <Typography sx={styles.subheader}>{eventContent.date}</Typography>
            </Stack>
          </Stack>

          <Box sx={styles.aigcContainer}>
            <AigcLanguageSelect />
          </Box>

          <MarkdownContainer markdown={eventContent.content} />
        </Box>
      ) : (
        <Stack spacing={18} width={"100%"}>
          <Stack alignItems={"center"} spacing={8} width={"100%"}>
            <Skeleton
              animation="wave"
              height={40}
              sx={styles.skeletonSpan}
              width={280}
            />
            <Skeleton
              animation="wave"
              height={20}
              sx={styles.skeletonSpan}
              width={180}
            />
          </Stack>

          <Stack spacing={4} width={"100%"}>
            <Skeleton animation="wave" height={20} sx={styles.skeletonSpan} />
            <Skeleton animation="wave" height={20} sx={styles.skeletonSpan} />
            <Skeleton
              animation="wave"
              height={20}
              sx={styles.skeletonSpan}
              width="50%"
            />
          </Stack>

          <Skeleton animation="wave" height={420} sx={styles.skeletonSpan} />

          <Stack spacing={4} width={"100%"}>
            <Skeleton animation="wave" height={20} sx={styles.skeletonSpan} />
            <Skeleton animation="wave" height={20} sx={styles.skeletonSpan} />
            <Skeleton
              animation="wave"
              height={20}
              sx={styles.skeletonSpan}
              width="50%"
            />
          </Stack>
        </Stack>
      )}
    </SectionLayout>
  );
};

export default Event;
