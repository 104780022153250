import {
  apiUrl,
  extractErrorMessage,
  prepareFormData,
} from "../utils/apiUtils";

export const fetchTranslations = async (formData) => {
  const body = `${prepareFormData(formData)}`;

  try {
    const response = await fetch(`${apiUrl}api/aigc/translate`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body,
      redirect: "follow",
    });

    const jsonData = await response.json();

    if (!jsonData.log) {
      return { data: jsonData.data, status: "resolved" };
    } else {
      throw extractErrorMessage(jsonData.log) || response.statusText;
    }
  } catch (err) {
    console.error(`ERROR ${err}`);

    return { data: err, status: "rejected" };
  }
};
