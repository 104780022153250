const globalReducer = (state, action) => {
  switch (action.type) {
    case "SET_AIGC_LANGUAGE":
      return { ...state, aigcLanguage: action.payload };

    case "SET_CURRENT_VIEW":
      return { ...state, currentView: action.payload };

    case "SET_LOADING":
      return { ...state, loading: action.payload };

    case "SET_LOADING_TRANSLATION":
      return { ...state, loadingTranslation: action.payload };

    case "SHOW_SNACKBAR":
      return { ...state, snackbar: action.payload };

    default:
      return state;
  }
};

export default globalReducer;
