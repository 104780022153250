import { theme } from "../../utils/theme";

export const styles = {
  grow: {
    flexGrow: 1,
    ml: "auto",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  menuItem: {
    color: theme.palette.grays.black,
    fontSize: theme.typography.fontSize * 0.88,
  },
  selectInputProps: {
    backgroundColor: theme.palette.grays[100],
    fontSize: theme.typography.fontSize * 0.88,
    p: 1,
    pl: 2,
    transition: "all 0.5s ease-in-out",
    "&:hover": {
      backgroundColor: theme.palette.grays[200],
    },
  },
};
