import { theme } from "../../../utils/theme";

const itemHeader = {
  display: "-webkit-box !important",
  fontSize: {
    xs: theme.typography.fontSize * 1.1,
    md: theme.typography.fontSize * 1.18,
  },
  fontWeight: 700,
  overflow: "hidden",
  textAlign: "start",
  textOverflow: "ellipsis",
  transition: "all 0.3s ease-in-out !important",
  whiteSpace: "normal",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
};

export const styles = {
  item: {
    alignItems: "center",
    backgroundColor: theme.palette.grays.white,
    border: `0px solid ${theme.palette.grays[300]}  `,
    boxShadow: "none",
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    justifyContent: "start",
    mb: 1,
    ml: 0,
    mr: { xs: 0, md: 0 },
    mt: 0,
    minWidth: 300,
    overflow: "hidden",
    position: "relative",
    transition: "all 0.3s ease-in-out !important",
    width: { xs: "100%", sm: "48%", md: "29%" },
    // "&:hover": {
    //   boxShadow:
    //     "0 0 24px rgba(37,43,129,.04), 0 44px 74px rgba(37,43,129,.06)",
    // },
  },
  itemAction: {
    alignItems: "center",
    color: theme.palette.grays.black,
    display: "flex",
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1,
    },
    fontWeight: 600,
    gap: 1,
    justifyContent: "start",
    lineHeight: "1.1",
  },
  itemBody: {
    gap: 10,
    height: "100%",
    pb: 6,
    // pl: 5,
    // pr: 5,
    pt: 4,
    width: "100%",
  },
  itemHeaderInactive: {
    ...itemHeader,
    color: theme.palette.grays.black,
  },
  itemHeaderActive: {
    ...itemHeader,
    color: theme.palette.secondary.main,
    textDecoration: "underline",
  },
  itemImage: {
    backgroundPosition: "center top !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    height: "100%",
    width: "100%",
  },
  itemImageContainer: {
    borderColor: theme.palette.grays[300],
    borderRadius: 2,
    borderStyle: "solid",
    borderWidth: 0,
    height: { xs: 240, md: 240 },
    overflow: "hidden",
    width: { xs: "100%", md: "100%" },
    zIndex: 1,
  },
  itemText: {
    color: theme.palette.grays[700],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1,
    },
    fontWeight: 500,
    lineHeight: 1.1,
    textAlign: "center",
  },
  itemTextDivider: {
    color: theme.palette.grays.black,
  },
  margin: {
    display: "flex",
    margin: 1,
  },
};
