import circle_1 from "../../assets/background/circle_1.png";
import circle_2 from "../../assets/background/circle_2.png";
import circle_3 from "../../assets/background/circle_3.png";


export const styles = {
  backgroundDeco1: {
    background: `url(${circle_1})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: 800,
    position: "absolute",
    width: 800,
  },
  backgroundDeco2: {
    background: `url(${circle_2})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: 750,
    position: "absolute",
    width: 750,
  },
  backgroundDeco3: {
    background: `url(${circle_3})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "contain !important",
    height: 400,
    position: "absolute",
    width: 400,
  },
  backgroundDecoContainer: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: { xs: -560, md: -0 },
    width: "100%",
    zIndex: -1,
  },
};
