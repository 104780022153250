import React, { useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGemoji from "remark-gemoji";
import remarkGfm from "remark-gfm";
import remarkHint from "remark-hint";
import remarkParse from "remark-parse";
import { unified } from "unified";

import { fetchTranslations } from "../../../api/AigcApi";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { bodyWidth } from "../../../utils/theme";

const MarkdownContainer = ({ markdown }) => {
  const { state, dispatch } = useContext(GlobalContext);
  const { aigcLanguage } = state;

  const [defaultMarkdownContent, setDefaultMarkdownContent] = useState("");
  const [markdownContent, setMarkdownContent] = useState("");
  const [markdownParagraphs, setMarkdownParagraphs] = useState([]);

  useEffect(() => {
    window.scrollTo({ top: 0 });

    fetch(markdown)
      .then((res) => res.text())
      .then((text) => {
        setDefaultMarkdownContent(text);
        setMarkdownContent(text);

        extractText(text);
      });
  }, []);

  useEffect(() => {
    if (!aigcLanguage) return;

    if (aigcLanguage !== "en") {
      // Call translate service for non-english languages
      translateMarkdownContent();
    } else {
      // Restore default markdown content (English currently)
      setMarkdownContent(defaultMarkdownContent);
    }
  }, [aigcLanguage]);

  /** Call translate service */
  const translateMarkdownContent = async () => {
    dispatch({
      type: "SET_LOADING_TRANSLATION",
      payload: true,
    });

    // Set current markdown content to default content since extracted paragraphs are in default language (currently English)
    let currentMarkdownContent = defaultMarkdownContent;

    // Traverse each extracted paragraph (in default language) and call translate service with each paragraph as an argument in the API body
    for (const text of markdownParagraphs) {
      const formData = {
        dest: aigcLanguage,
        text,
        type: "text",
      };

      const response = await fetchTranslations(formData);

      if (response.status === "resolved") {
        // Replace extracted paragraph with returned translation
        currentMarkdownContent = currentMarkdownContent.replace(
          text,
          response.data
        );
      }

      // Store translated paragraph in state
      setMarkdownContent(currentMarkdownContent);
    }

    dispatch({
      type: "SET_LOADING_TRANSLATION",
      payload: false,
    });
  };

  /** Extract string text from markdown content and store in state */
  const extractText = (markdown) => {
    // Parse markdown into an Abstract Syntax Tree (AST)
    const processor = unified().use(remarkParse);
    const ast = processor.parse(markdown);

    // Array to store text nodes
    const textNodes = [];

    // Recursive function to traverse the AST and collect text nodes
    const traverseAst = (node) => {
      if (node.type === "text") {
        textNodes.push(node.value);
      }

      if (node.children) {
        node.children.forEach(traverseAst);
      }
    };

    traverseAst(ast);

    // Store extracted text as array in state
    setMarkdownParagraphs(textNodes);
  };

  const markdownComponents = {
    p: ({ node, children, ...props }) => {
      return (
        <p
          style={{
            width: "90%",
            margin: "0 auto",
            lineHeight: "1.6",
          }}
          {...props}
        >
          {children}
        </p>
      );
    },
    img: ({ ...props }) => {
      const substrings = props.alt?.split("{{");
      const alt = substrings[0].trim();

      return (
        <img
          src={props.src}
          alt={alt}
          style={{
            height: "auto",
            maxWidth: bodyWidth.maxWidth,
            width: "100%",
          }}
        />
      );
    },
  };

  return (
    <ReactMarkdown
      components={markdownComponents}
      remarkPlugins={[remarkGemoji, remarkGfm, remarkHint]}
      rehypePlugins={[rehypeRaw]}
    >
      {markdownContent}
    </ReactMarkdown>
  );
};

export default MarkdownContainer;
