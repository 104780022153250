import blog_bg from "../../assets/background/blog_bg.jpg";
import { theme } from "../../utils/theme";

const HEADER_HEIGHT = { xs: 320, sm: 280 };

export const styles = {
  background: {
    backgroundColor: theme.palette.secondary.dark,
    // background:
    //   "linear-gradient(180deg, rgba(4, 104, 214, 0.34) 1.09%, rgba(225, 150, 198, 0.34) 42.33%, rgba(255, 190, 170, 0.34) 69.9%)",
    backgroundPosition: "center bottom !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    // filter: "brightness(1.45) opacity(1)",
    filter: "brightness(0.6) opacity(1)",
    height: HEADER_HEIGHT,
    left: { xs: 0, sm: 0 },
    position: "absolute",
    top: 0,
    width: "100%",
    zIndex: 1,
  },
  backgroundOverlay: {
    background: `url(${blog_bg})`,
    backgroundPosition: "center !important",
    backgroundRepeat: "no-repeat !important",
    backgroundSize: "cover !important",
    bottom: 0,
    filter: "brightness(0.7) hue-rotate(0deg) opacity(0.5)",
    height: "100%",
    left: { xs: 0, sm: 0 },
    position: "absolute",
    width: { xs: "200%", sm: "120%" },
  },
  bodyText: {
    color: theme.palette.grays[300],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1.08,
    },
    fontWeight: 400,
    textAlign: { xs: "center", md: "center" },
    textTransform: "none",
  },
  indexItem: {
    alignItems: "center",
    display: "flex",
    gap: 4,
    width: "100%",
  },
  indexItemBullet: {
    backgroundColor: theme.palette.secondary.main,
    height: 6,
    maxHeight: 6,
    minWidth: 6,
  },
  indexItemText: {
    color: theme.palette.grays[900],
    fontSize: {
      xs: theme.typography.fontSize * 1,
      md: theme.typography.fontSize * 1,
    },
    lineHeight: 1.2,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  indexItemTitle: {
    display: "-webkit-box !important",
    fontSize: {
      xs: theme.typography.fontSize * 1.13,
      md: theme.typography.fontSize * 1.13,
    },
    fontWeight: 700,
    lineHeight: 1.4,
    overflow: "hidden",
    textAlign: "start",
    textOverflow: "ellipsis",
    transition: "all 0.3s ease-in-out !important",
    whiteSpace: "normal",
    WebkitLineClamp: "2",
    WebkitBoxOrient: "vertical",
  },
  item: {
    py: 8,
  },
  itemsContainer: {
    gap: 5,
    pb: 4,
  },
  scrollableColumn: {
    alignItems: "start",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 12,
    height: "fit-content",
    mb: { xs: 0, md: 22 },
    mt: { xs: 0, md: 22 },
    position: "relative",
    width: "100%",
  },
  stickyColumn: {
    display: { xs: "flex", md: "flex" },
    color: theme.palette.grays["black"],
    fontWeight: 700,
    gap: 6,
    height: "fit-content",
    pt: { xs: 8, md: 28 },
    position: { xs: "relative", md: "sticky" },
    right: { xs: 0, md: 30 },
    top: { xs: 0, md: 80 },
    width: { xs: "100%", md: "25%" },
  },
  splitLayout: {
    alignItems: "start",
    display: "flex",
    flexDirection: { xs: "column-reverse", md: "row" },
    gap: 12,
    justifyContent: "start",
    width: "100%",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  header: {
    alignItems: { xs: "center", sm: "start" },
    height: HEADER_HEIGHT,
    position: "relative",
    width: { xs: "100%", md: "100%" },
  },
  headerText: {
    color: theme.palette.grays["white"],
    fontSize: {
      xs: theme.typography.fontSize * 3,
      md: theme.typography.fontSize * 4,
    },
    fontWeight: 700,
    letterSpacing: 0.9,
    lineHeight: { xs: 1, md: 1.1 },
    textAlign: { xs: "center", md: "center" },
    textTransform: "capitalize",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  nullContainer: {
    alignItems: "center",
    color: theme.palette.grays[600],
    display: "flex",
    fontSize: theme.typography.fontSize * 1,
    justifyContent: { xs: "center", md: "start" },
    textAlign: "center",
    width: "100%",
  },
  root: {
    height: "100%",
    justifyContent: "center",
    position: "relative",
    width: "100%",
  },
};
