import {
  Box,
  Button,
  InputBase,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import emailjs from "@emailjs/browser";
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { RiArrowDownSLine, RiArrowUpSLine } from "react-icons/ri";
import { HiArrowLongRight } from "react-icons/hi2";

import SectionLayout from "../../layouts/SectionLayout";
import SlideTransition from "../SlideTransition";

import { GlobalContext } from "../../contexts/GlobalContext";
import { useInView } from "../../hooks/useInView";
import { content } from "../../utils/content";
import { NAV_SUBROUTES } from "../../utils/routes";

import { styles } from "./style";

const INIT_DETAILS = {
  name: "",
  lastName: "",
  email: "",
  phone: "",
  purpose: "",
  message: "",
  website: "IP Management Contact Form",
};

const ContactForm = () => {
  const { state, dispatch } = useContext(GlobalContext);

  const [setRef, visible] = useInView({
    threshold: 0.1,
  });
  const { t } = useTranslation();

  const [contactDetails, setContactDetails] = useState(INIT_DETAILS);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);
  const [showRequired, setShowRequired] = useState(false);

  const handleSelectPurpose = (option) => () => {
    setContactDetails({ ...contactDetails, purpose: option });
    setInvalidEmail(false);
    setIsSelectMenuOpen(false);
    setShowRequired(false);
  };

  const handleChangeInput = (type) => (event) => {
    setContactDetails({ ...contactDetails, [type]: event.target.value });
    setInvalidEmail(false);
    setShowRequired(false);
  };

  const checkIsEmpty = () => {
    const checker =
      contactDetails.name.trim() === "" ||
      contactDetails.lastName.trim() === "" ||
      contactDetails.email.trim() === "" ||
      contactDetails.message.trim() === "" ||
      contactDetails.purpose === ""
        ? true
        : false;
    setShowRequired(checker);

    return checker;
  };

  const validateEmail = (email) => {
    const regex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validate = regex.test(String(email).toLowerCase());
    setInvalidEmail(!validate);

    return validate;
  };

  const handleSubmitMessage = async (event) => {
    event.preventDefault();

    const isEmpty = checkIsEmpty();
    const validEmail = validateEmail(contactDetails.email.trim());

    if (validEmail && !isEmpty) {
      setIsLoading(true);

      try {
        const response = await emailjs.send(
          process.env.REACT_APP_EMAIL_SERVICE_ID,
          process.env.REACT_APP_EMAIL_TEMPLATE_ID,
          contactDetails,
          process.env.REACT_APP_EMAIL_PUBLIC_KEY
        );

        setContactDetails(INIT_DETAILS);

        dispatch({
          type: "SHOW_SNACKBAR",
          payload: {
            message: t(
              "Thanks for your feedback. We will reply to you shortly."
            ),
            isOpen: true,
          },
        });
      } catch (err) {
        console.error(err);
        if (err) {
          dispatch({
            type: "SHOW_SNACKBAR",
            payload: {
              message: t(
                "Oops... Your message was not sent. Please try again."
              ),
              isOpen: true,
            },
          });
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Box ref={setRef} sx={styles.contactRoot}>
      <SectionLayout id={NAV_SUBROUTES.get("CONTACT").ID}>
        <SlideTransition appear={visible} timeout={700}>
          <Stack spacing={4} justifyContent={"center"} sx={styles.header}>
            <Typography sx={styles.headerText}>
              {t(content.contactForm.header)}
            </Typography>

            <Typography sx={styles.bodyText}>
              {t(content.contactForm.subheader)}
            </Typography>
          </Stack>
        </SlideTransition>

        <Box sx={styles.formContainer}>
          <Box sx={styles.formRow}>
            <Box sx={styles.selectFormContainer}>
              <Box
                sx={styles.selectButton}
                onClick={() => setIsSelectMenuOpen(!isSelectMenuOpen)}
              >
                {contactDetails.purpose !== "" ? (
                  <Typography sx={styles.selectFormValue}>
                    {t(contactDetails.purpose)}
                  </Typography>
                ) : (
                  <Typography sx={styles.selectFormLabel}>
                    {t(content.contactForm.optionPlaceholder)}
                  </Typography>
                )}

                <Box sx={styles.grow} />

                {isSelectMenuOpen ? (
                  <RiArrowUpSLine size="1.1em" />
                ) : (
                  <RiArrowDownSLine size="1.1em" />
                )}
              </Box>

              {isSelectMenuOpen && (
                <Box sx={styles.selectMenu}>
                  <Typography sx={styles.selectMenuLabel}>
                    {t(content.contactForm.optionLabel)}
                  </Typography>
                  {content.contactForm.options.map((option, index) => {
                    return (
                      <Box
                        sx={styles.selectOption}
                        key={index}
                        onClick={handleSelectPurpose(option)}
                      >
                        <Typography
                          sx={
                            option === contactDetails.purpose
                              ? styles.selectOptionLabelActive
                              : styles.selectOptionLabel
                          }
                        >
                          {t(option)}
                        </Typography>
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Box>

          <Box sx={styles.margin} />

          <Box sx={styles.formRow}>
            <Paper sx={styles.input}>
              <InputBase
                onChange={handleChangeInput("name")}
                placeholder={t("Name*")}
                sx={styles.inputBase}
                value={contactDetails.name}
              />
            </Paper>

            <Paper sx={styles.input}>
              <InputBase
                onChange={handleChangeInput("lastName")}
                placeholder={t("Last name*")}
                sx={styles.inputBase}
                value={contactDetails.lastName}
              />
            </Paper>
          </Box>

          <Box sx={styles.formRow}>
            <Paper sx={styles.input}>
              <InputBase
                onChange={handleChangeInput("email")}
                placeholder={t("Email address*")}
                sx={styles.inputBase}
                value={contactDetails.email}
              />
            </Paper>

            <Paper sx={styles.input}>
              <InputBase
                onChange={handleChangeInput("phone")}
                placeholder={t("Phone number*")}
                sx={styles.inputBase}
                value={contactDetails.phone}
              />
            </Paper>
          </Box>

          <Box sx={styles.formRow}>
            <Paper sx={styles.input}>
              <InputBase
                multiline
                onChange={handleChangeInput("message")}
                placeholder={t("Your message*")}
                rows={8}
                sx={styles.inputBase}
                value={contactDetails.message}
              />
            </Paper>
          </Box>

          <Box sx={styles.margin} />

          <Box sx={styles.errorUnit}>
            {showRequired && (
              <Typography sx={styles.error}>
                {t("Please complete all required fields.")}
              </Typography>
            )}

            {invalidEmail && (
              <Typography sx={styles.error}>
                {t("Please provide a valid email address.")}
              </Typography>
            )}
          </Box>

          <Box sx={styles.margin} />

          <Box sx={styles.formRow}>
            <Button
              sx={styles.button}
              color="secondary"
              onClick={handleSubmitMessage}
              onMouseEnter={() => setMouseOver(true)}
              onMouseLeave={() => setMouseOver(false)}
              variant="contained"
            >
              {isLoading ? (
                "Sending..."
              ) : (
                <>
                  {t(content.contactForm.action)}

                  <Box sx={styles.margin} />

                  <HiArrowLongRight
                    size={"1.4em"}
                    style={{
                      transform: mouseOver
                        ? "translateX(8px)"
                        : "translateX(2px)",
                      transition: "all 0.3s ease-in-out",
                    }}
                  />
                </>
              )}
            </Button>
          </Box>
        </Box>
      </SectionLayout>

      {/* <Box sx={styles.background} /> */}
    </Box>
  );
};

export default ContactForm;
