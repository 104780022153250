import { theme } from "../../utils/theme";

export const styles = {
  aigcContainer: {
    display: "flex",
    flex: 1,
  },
  breadcrumbContainer: {
    width: { xs: "100%", sm: "90%" },
  },
  breadcrumbLabel: {
    color: theme.palette.grays["black"],
    fontSize: theme.typography.fontSize * 0.88,
  },
  breadcrumbLink: {
    color: theme.palette.primary["main"],
    fontSize: theme.typography.fontSize * 0.88,
  },
  gridContainer: {
    alignItems: "start",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    position: "relative",
  },
  grow: {
    flexGrow: 1,
    marginLeft: "auto",
  },
  image: {
    borderRadius: 8,
    marginBottom: 20,
    marginTop: 20,
    width: "90%",
  },
  margin: {
    display: "flex",
    margin: 1,
  },
  root: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    height: "100%",
    justifyContent: "start",
    position: "relative",
    width: "100%",
  },
  header: {
    color: theme.palette.grays.black,
    fontSize: {
      xs: theme.typography.fontSize * 2.5,
      sm: theme.typography.fontSize * 4,
    },
    fontWeight: 900,
    lineHeight: 1.4,
    textAlign: "center",
  },
  skeletonSpan: {
    backgroundColor: theme.palette.grays[200],
    borderRadius: 1,
    height: 24,
    transform: "none",
    width: "100%",
  },
  subheader: {
    color: theme.palette.grays[700],
    fontSize: theme.typography.fontSize * 0.88,
    fontWeight: 500,
    textAlign: "center",
    textTransform: "uppercase",
  },
};
