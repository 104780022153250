export const apiUrl = process.env.REACT_APP_BACKEND_API_URL;

export const extractErrorMessage = (str) => {
  /** Error message is second item in error log, embedded between '<>'. */
  const extractMessage = str
    .match(/<(.*?)>/g)?.[1]
    .replace("<", "")
    .replace(">", "");

  /** Convert first char of error message to upper case */
  return extractMessage
    ? extractMessage.charAt(0).toUpperCase() + extractMessage.slice(1)
    : "Something went wrong. Please try again.";
};

export const prepareFormData = (data) => {
  let arrayOfProcessedStrings = [];

  for (const key in data) {
    const value =
      typeof data[key] === "object"
        ? /** Prepare the format of stringified obj by (1) Wrapping string in double quotes and (2) Replacing all double quotes to single quotes */
          data[key]?.length === undefined
          ? `"${JSON.stringify(data[key]).replace(/['"]+/g, "'")}"`
          : JSON.stringify(data[key])
        : typeof data[key] === "string"
        ? JSON.stringify(data[key])
        : data[key];

    const newStr = `"${key}": ${value}`;
    arrayOfProcessedStrings = [...arrayOfProcessedStrings, newStr];
  }

  return `{${arrayOfProcessedStrings.join(", ")}}`;
};
