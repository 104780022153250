import { DOC_ROUTES, NAV_SUBROUTES } from "./routes";

export const NAV_OPTIONS = new Map([
  ["OVERVIEW", NAV_SUBROUTES.get("OVERVIEW")],
  ["VALUE", NAV_SUBROUTES.get("VALUE")],
  ["PRODUCT", NAV_SUBROUTES.get("PRODUCT")],
  ["DOCS", NAV_SUBROUTES.get("DOCS")],
  ["BLOG", NAV_SUBROUTES.get("BLOG")],
  ["EVENTS_LIST", NAV_SUBROUTES.get("EVENTS_LIST")],
  ["CONTACT", NAV_SUBROUTES.get("CONTACT")],
]);

export const DOCS_OPTIONS = new Map([
  ["INTRODUCTION", DOC_ROUTES.get("INTRODUCTION")],
  ["GETTING_STARTED", DOC_ROUTES.get("GETTING_STARTED")],
  ["ENTERPRISE", DOC_ROUTES.get("ENTERPRISE")],
  ["UNIVERSAL", DOC_ROUTES.get("UNIVERSAL")],
]);

export const LANGUAGES_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      LABEL: "English",
      KEY: "en",
    },
  ],
  [
    "CHINESE",
    {
      LABEL: "中文",
      KEY: "zh-cn",
    },
  ],
]);

export const BLOG_LANGUAGE_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      KEY: "en",
      LABEL: "English",
      VALUE: "en",
    },
  ],
  [
    "CHINESE",
    {
      KEY: "zh-cn",
      LABEL: "中文",
      VALUE: "cn",
    },
  ],
]);

export const TRANSLATE_OPTIONS = new Map([
  [
    "ENGLISH",
    {
      LABEL: "English",
      VALUE: "en",
    },
  ],
  [
    "ZH-CN",
    {
      LABEL: "简体中文",
      VALUE: "zh-cn",
    },
  ],
  // [
  //   "ZH-TW",
  //   {
  //     LABEL: "繁体中文",
  //     VALUE: "zh-tw",
  //   },
  // ],
  // [
  //   "FRENCH",
  //   {
  //     LABEL: "French",
  //     VALUE: "FR",
  //   },
  // ],
  // [
  //   "GERMAN",
  //   {
  //     LABEL: "German",
  //     VALUE: "DE",
  //   },
  // ],
  // [
  //   "ITALIAN",
  //   {
  //     LABEL: "Italian",
  //     VALUE: "IT",
  //   },
  // ],
  // [
  //   "JAPANESE",
  //   {
  //     LABEL: "Japanese",
  //     VALUE: "JA",
  //   },
  // ],
  // [
  //   "KOREAN",
  //   {
  //     LABEL: "Korean",
  //     VALUE: "KO",
  //   },
  // ],
]);
